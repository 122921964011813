<template>
    <div class="ESOne">
        <div style="margin-top: 40px;" class="ESOne-straight">
            <span class="demonstration">箱内温度</span>
            <el-progress :percentage="90" color="#51A34B"></el-progress>
            <span style="font-size: 14px;color: #696969;">0</span><span style="margin-left: 75%;font-size: 14px;color: #696969;">40℃</span>
        </div>
        <div style="margin-top: 40px;" class="ESOne-straight">
            <span class="demonstration">风机流量</span>
            <el-progress :percentage="67" color="#FF7300"></el-progress>
            <span style="font-size: 14px;color: #696969;">0</span><span style="margin-left: 68%;font-size: 14px;color: #696969;">4m³/min</span>
        </div>
        <div style="margin-top: 40px;" class="ESOne-straight">
            <span class="demonstration">湿度</span>
            <el-progress :percentage="100" color="#F03040"></el-progress>
            <span style="font-size: 14px;color: #696969;">0</span><span style="margin-left: 73%;font-size: 14px;color: #696969;">40%</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            colors: "#51A34B"
        }
    },
}
</script>
<style lang="less">
.ESOne {
    .el-progress-circle {
        width: 120px !important;
        height: 120px !important;
    }
    .ESOne-straight {
        .el-progress__text {
            position: absolute;
            top: -12px;
            right: 52px;
        }
    }
}
</style>