<template>
    <div ref="text_modle13" :style="{ width: Width, height: Height, position: 'relative' }">
        <div class="card-content2">
            <chartBoard ref="main_modle13" :option="chartOption1" />
        </div>
        <div style="position: absolute; top: 10%; right: 20px;">
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; height: 12px; border-radius: 50%;  background:#3366FF; margin-right: 4px;"></span><span>充电量</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; height: 12px; border-radius: 50%;  background:#B4D0FF; margin-right: 4px;"></span><span>放电量</span>
            </div>
        </div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import { color } from 'echarts';
export default {
    name: 'detailsmask001',
    components: {
        chartBoard: () => import('@/component/chartBoard.vue'),
    },
    props: ['value', 'shuaxin', 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            chartOption1: {},
            dialogCategory: false,

        }
    },

    methods: {
        a() {
            console.log(this.checked1)
        },
        initEchart() {
            // this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: '20%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    },
                    extraCssText: 'width:140px;height:40px;',
                    formatter: function (params) {
                        return params[0].name + ' : ' + '(' + params[0].value + ':' + params[1].value + ")"
                    }
                },
                xAxis: {
                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                yAxis: {
                    name: "单位（kWh）",
                    axisTick: {
                        show: true
                    },
                    nameTextStyle: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    // 控制数据参数最大值和最小值
                    interval: 200,
                    max: 1000

                },
                // 控住柱状图样式
                series: [
                    {
                        name: 'Rainfall',
                        type: 'bar',
                        barWidth: 10,
                        itemStyle: {
                            color: "#3366FF"
                        },
                        data: [
                            880, 900, 950, 820, 990, 800, 800, 750, 820, 890, 800, 720
                        ],
                    },
                    {
                        name: 'Evaporation',
                        type: 'bar',
                        barWidth: 10,
                        itemStyle: {
                            color: "#B4D0FF"
                        },
                        data: [
                            600, 830, 600, 700, 877, 700, 680, 650, 720, 810, 760, 600
                        ],
                    }
                ]
            };

            this.updateChart('chart1', 'chartOption1', option13)
        },
        updateChart: function (refName, optionName, option13 = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option13
            setTimeout(() => {
                this.$refs.main_modle13.updateChartView()
            }, 500)
        },

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>