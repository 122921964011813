<template>
    <div>
        <div style="display: flex; width: 100%; margin-top: 20px; margin-bottom: 30px;">
            <div style=" width: 40%;">
                <dv-water-level-pond :config="config" style="width:100px;height:150px; margin-left: 25px;" />
                <i class="el-icon-bottom" style="font-size: 30px; margin-left: 60px; margin-top: 10px; margin-bottom: 10px;"></i>
                <div style="width:100%; height: 90px; background-color:#f5f5f5; padding: 20px; ">
                    <span style="font-size: 14px;color: #1f1f1f;">SOC</span>
                    <div style="color: #1f1f1f;font-size: 36px;">50%</div>
                </div>
            </div>
            <div style="margin-left: 10%; width: 40%;">
                <dv-water-level-pond :config="configA" style="width:100px;height:150px; margin-left: 25px;" />
                <i class="el-icon-bottom" style="font-size: 30px; margin-left: 60px; margin-top: 10px; margin-bottom: 10px;"></i>
                <div style="width:100%; height: 90px; background-color:#f5f5f5; padding: 20px; ">
                    <span style="font-size: 14px;color: #1f1f1f;">SOC</span>
                    <div style="color: #1f1f1f;font-size: 36px;">83%</div>
                </div>
            </div>
        </div>
        <div>
            <div v-for="(item, index) in newData" :key="index"
                style="display: flex; border: 1px solid #ebebeb;  height: 30px; line-height: 30px; text-align: center; width: 90%; margin: auto; margin-bottom:-1px;">
                <div style="width: 70%; text-align: left; padding-left: 20px;">{{ item.name }}</div>
                <div style="width: 20%; background-color: #EBEBEB;">{{ item.num }}</div>
                <div style="width: 10%; background-color: #EBEBEB;">{{ item.Company }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            config: {
                data: [50],
                waveNum: 1,
                waveHeight: 0,
                colors: ["#FF7300", "#FF7300"]
            },
            configA: {
                data: [83],
                waveNum: 1,
                waveHeight: 0,
                colors: ["#51A34B", "#51A34B"]
            },
            newData: [
                {
                    name: "充放电比",
                    num: 20,
                    Company: "%",
                },
                {
                    name: "最高温度",
                    num: 75,
                    Company: "℃",
                },
                {
                    name: "日总充电量",
                    num: 2780,
                    Company: "kWh",
                },
                {
                    name: "日总放电量",
                    num: 2780,
                    Company: "kWh",
                },
            ]
        }
    },
}
</script>