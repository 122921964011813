<template>
    <div ref="text_modle13" :style="{ width: Width, height: Height, position: 'relative' }" class="distortionRate">
        <div style="display: flex;">
            <div style="width: 40%;">
                <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;" @change="changeTheme">
                    <el-radio-button :label="1">A相</el-radio-button>
                    <el-radio-button :label="2">B相</el-radio-button>
                    <el-radio-button :label="3">C相</el-radio-button>
                </el-radio-group>
            </div>
            <div style="margin-left: 20px;">
                <el-select v-model="value" multiple filterable allow-create default-first-option placeholder="请选择文章标签">
                    <el-option v-for="item in distortionRate" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="card-content2">
            <chartBoard ref="main_modle13" :option="chartOption1" />
        </div>
        <div style="position: absolute; top: 20%; right: 20px;">
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #EBEBEB; margin-right: 4px;"></span><span>1#主变</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #1E6119; margin-right: 4px;"></span><span>2#主变</span>
            </div>  
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #1191E6; margin-right: 4px;"></span><span>3#主变</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #F03040; margin-right: 4px;"></span><span>4#主变</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #CB2BD5; margin-right: 4px;"></span><span>5#主变</span>
            </div>
        </div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import { color } from 'echarts';
export default {
    name: 'detailsmask001',
    components: {
        chartBoard: () => import('@/component/chartBoard.vue'),
    },
    props: [ 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            chartOption1: {},
            dialogCategory: false,
            isCollapse: true,
            value:"",
            distortionRate: [
                {
                    value: '1',
                    label: '1#主变进线框'
                },
                {
                    value: '2',
                    label: '2#主变进线框'
                },
                {
                    value: '3',
                    label: '3#主变进线框'
                },
                {
                    value: '4',
                    label: '4#主变进线框'
                },
                {
                    value: '5',
                    label: '5#主变进线框'
                },
            ]
        }
    },

    methods: {
        initEchart() {
            // this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: '20%',
                    containLabel: true
                },
                xAxis: {
                    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                yAxis: {
                    name: "（KW）",
                    axisTick: {
                        show: true
                    },
                    nameTextStyle: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    // 控制数据参数最大值和最小值
                    interval: 200,
                    max: 1000

                },
                // 控住柱状图样式
                series: [
                    {
                        name: 'Fake Data',
                        type: 'line',
                        itemStyle: {
                            color: "#EBEBEB"
                        },
                  
                        smooth:false,
                        data: [580, 360, 480, 700, 500, 420, 470, 450, 500, 520, 540, 600,]
                    }, {
                        name: 'Fake Data',
                        type: 'line',
                        itemStyle: {
                            color: "#1E6119"
                        },
                        
                        smooth:false,
                        data: [400, 260, 480, 600, 700, 320, 570, 550, 100, 320, 340, 100,]
                    },
                    {
                        name: 'Fake Data',
                        type: 'line',
                        itemStyle: {
                            color: "#1191E6"
                        },
                        showSymbol: false,
                        smooth:false,
                        data: [300, 360, 380, 300, 300, 320, 370, 350, 300, 320, 340, 300,]
                    }, {
                        name: 'Fake Data',
                        type: 'line',
                        itemStyle: {
                            color: "#F03040"
                        },
                        showSymbol: false,
                        smooth:false,
                        data: [200, 260, 280, 200, 200, 220, 270, 250, 200, 220, 240, 200,]
                    }, {
                        name: 'Fake Data',
                        type: 'line',
                        smooth:false,
                        itemStyle: {
                            color: "#CB2BD5"
                        },
                        showSymbol: false,
                        data: [500, 560, 580, 500, 500, 520, 570, 550, 500, 520, 540, 500,]
                    }]
            };
            this.updateChart('chart1', 'chartOption1', option13)
        },
        updateChart: function (refName, optionName, option13 = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option13
            setTimeout(() => {
                this.$refs.main_modle13.updateChartView()
            }, 500)
        },
        changeTheme(val){
            console.log(val);
        }

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        // value() {//titleDec即为父组件的值，val参数为值
        //     this.initEchart()
        // },
        // shuaxin() {//titleDec即为父组件的值，val参数为值
        //     this.vm.resize()
        // },
    },
    mounted() {
        // this.getData()
        this.initEchart()
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>
<style lang="less">
.distortionRate {
    .el-input__inner {
        width: 100%;
        height: 32px;
    }

    .el-input__icon {
        line-height: 32px;
    }
    .el-radio-button__inner, .el-radio-group{
        height: 32px;
        line-height: 8px;
    }
}
</style>