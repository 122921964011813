<template>
    <div ref="text_modle13" :style="{ width: Width, height: Height, position: 'relative' }" class="newspaper">
        <div ref="main_modle13" :style="{ width: Width, height: Height }" style="margin: auto;"></div>
        <div>
            <div v-for="(item, index) in qualityData" :key="index"
                style="display: flex; border-bottom: 1px solid #EBEBEB; height: 35px; line-height: 35px; font-size: 14px; width: 90%; margin: auto;">
                <div v-if="item.evaluate == '优秀'"
                    style="border-left: 3px solid #2A35FF; height: 30px; margin-top: 2px;"></div>
                <div v-if="item.evaluate == '良好'"
                    style="border-left: 3px solid #4C80EE; height: 30px; margin-top: 2px;"></div>
                <div v-if="item.evaluate == '尚可' || item.evaluate == '低劣'"
                    style="border-left: 3px solid #B4D0FF; height: 30px; margin-top: 2px;"></div>
                <div style="width: 30%; margin-left: 20px;">{{ item.evaluate }}</div>
                <div style="width: 40%;">{{ item.num }}</div>
                <div style="width: 20%;"><span>{{ item.range }}</span></div>
            </div>
        </div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
    name: 'detailsmask001',
    components: {
    },
    props: ['value', 'shuaxin', 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            dialogCategory: false,
            alarmWarningData1: [],
            alarmWarningData2: [],
            alarmWarningData3: [],
            qualityData: [{
                evaluate: "优秀",
                num: "26,197.1万kWh",
                range: "3.8%"
            }, {
                evaluate: "优秀",
                num: "26,197.1万kWh",
                range: "3.8%"
            }, {
                evaluate: "优秀",
                num: "26,197.1万kWh",
                range: "3.8%"
            }, {
                evaluate: "优秀",
                num: "26,197.1万kWh",
                range: "3.8%"
            },]
        }
    },

    methods: {
        initEchart() {
            this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: '20%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'item'
                },
                title: [
                    {
                        text: '1163',
                        x: '45%',
                        y: '50%',
                        textAlign: 'center',
                        textStyle: {
                            fontSize: '18',
                            fontWeight: '600',
                            color: 'black',
                            textAlign: 'center',
                        },
                    },
                    {
                        text: '总计(个)',
                        x: '45%',
                        y: '40%',
                        textAlign: 'center',
                        textStyle: {
                            fontSize: '12',
                            fontWeight: '400',
                            color: '#333333',
                            textAlign: 'center',
                        },
                    }
                ],
                color: ["#2A35FF", "#4C80EE", "#B4D0FF"],
                // 控住柱状图样式
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['60%', '80%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        // emphasis: {
                        //     label: {
                        //         show: false,
                        //         fontSize: 20,
                        //         fontWeight: 'bold'
                        //     }
                        // },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 1048, },
                            { value: 735, },
                            { value: 580, },
                        ]
                    }
                ]
            };

            this.vm.setOption(option13);
        }

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>
<style lang="less">
.newspaper {
    .el-input__inner {
        width: 256px;
        height: 32px;
    }

    .el-input__icon {
        line-height: 32px;
    }
}
</style>