<template>
    <div ref="text_modle13" style="width: 100%; height: 310px;">
        <div class="card-content2">
            <chartBoard ref="main_modle13" :option="chartOption1" />
        </div>
        <div style="position: absolute; top: 20%; right: 20px;">
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #F03040; margin-right: 4px;"></span><span>A相</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #FF7300; margin-right: 4px;"></span><span>B相</span>
            </div>  
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #1191E6; margin-right: 4px;"></span><span>C相</span>
            </div>
        </div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import { color } from 'echarts';
export default {
    name: 'detailsmask001',
    components: {
        chartBoard: () => import('@/component/chartBoard.vue'),
    },
    props: ['value', 'shuaxin', 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            chartOption1: {},
            dialogCategory: false,

        }
    },

    methods: {
        a() {
            console.log(this.checked1)
        },
        initEchart() {
            // this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: '20%',
                    containLabel: true
                },
                xAxis: {
                    data: ['8:01','8:02','8:03','8:04','8:05','8:06','8:07','8:08','8:09','8:10','8:11','8:12','8:13','8:14','8:15','8:16','8:17','8:18','8:19','8:20','8:21','8:22','8:23','8:24'],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    interval: 1,
                },
                yAxis: {
                    name: "单位（A）",
                    axisTick: {
                        show: true
                    },
                    nameTextStyle: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    // 控制数据参数最大值和最小值
                    interval: 200,
                    max: 400

                },
                // dataZoom: [
                //     {
                //     type: 'inside',
                //     start: 0,
                //     end: 100,
                //     height: 10,
                //     bottom: 0,
                //     },
                //     {
                //     start: 0,
                //     end: 100,
                //     height: 20,
                //     top:"96%",
                //     bottom: 0,
                //     }
                // ],
                // 控住柱状图样式
                series: [
                    {
                        name: 'Fake Data',
                        type: 'line',
                        itemStyle: {
                            color: "#F03040"
                        },
                        smooth:false,
                        showSymbol: false,
                        data: [380, 360, 280, 100, 300, -120, -370, -150, 300, 120, -340, 100,]
                    }, {
                        name: 'Fake',
                        type: 'line',
                        itemStyle: {
                            color: "#FF7300"
                        },
                        showSymbol: false,
                        smooth:false,
                        data: [300, 260, 380, 200, 200, -220, -270, -250, 200, 220, -240, 200,]
                    },
                    {
                        name: 'Fake',
                        type: 'line',
                        itemStyle: {
                            color: "#1191E6"
                        },
                        showSymbol: false,
                        smooth:false,
                        data: [200, 160, 180, 300, 100, -320, -170, -350, 100, 320, -140, 300,]
                    },]
            };
            this.updateChart('chart1', 'chartOption1', option13)
        },
        updateChart: function (refName, optionName, option13 = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option13
            setTimeout(() => {
                this.$refs.main_modle13.updateChartView()
            }, 500)
        },

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>