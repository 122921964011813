<template>
    <div ref="text_modle13" :style="{ width: Width, height: Height, position: 'relative' }" class="powerMonitor">
        <div style="margin-left: 20px;">
            <div style="position: relative;">
                <div style="width: 140px;height: 120px;background-color: #205DE8;border-radius: 6px; color: #fff;">
                    <div style="padding-top: 10px; text-align: center;">变压器负载率</div>
                    <div style="font-size: 30px; text-align: center; padding: 5px 0;">56%</div>
                    <div style="text-align: center;">负载率>20%</div>
                </div>
                <div
                    style="width: 0px;height: 0px;border-style: solid;border-width: 30px 70px 0 70px;border-color:#205DE8 #fff #fff #fff; position: absolute; top: 117px;left: 1px;">
                </div>
            </div>
            <div class="speed">
                <div class="item animate__bounceInLeft"
                    :style="{ height: `${item.speed}%`, background: `${item.color}` }">
                </div>
                <div class="title">{{ 100 - item.speed }}%</div>
            </div>
        </div>
        <div style="margin-left: 70px; height: 400px; position: relative;">
            <div>
                <div style="position: absolute; left: -26px;">
                    <div v-for="(item, index) in numValue" :key="index" style="padding-bottom: 62px;">{{ item }}</div>
                </div>
                <div style="width: 40px; height: 400px;">
                    <div style="height: 40%; width: 30px; background-color: #D21423;"></div>
                    <div style="height: 40%; width: 30px; border-top: 1px solid black; background-color: #FF7300;">
                    </div>
                    <div style="height: 20%; width: 30px; border-top: 1px solid black; background-color: #51A34B;">
                    </div>
                </div>
            </div>
            <div style="position: absolute;width: 100px;height: 100px; left: 45px;" :style="{top:`${Top}%`}" >
                <div style="font-size: 14px;color: #000000;">三相不平衡度</div>
                <div style="position: relative;">
                    <i class="el-icon-caret-left" style="position: absolute; left: -16px;"></i>
                    <span style="font-size: 36px;color: #1f1f1f;">44.5%</span>
                </div>
            </div>
        </div>
        <div ref="main_modle13"  style="margin-left: 200px; width: 30%;"></div>
    </div>
</template> 
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import { color } from 'echarts';
export default {
    data() { 
        return {
            item: {
                speed: 100 - 80,
                color: '#EBEBEB'
            },
            Top:100-(44.5*2),
            // numValue:["0","10","20","30","40","50","60","70","80","90","100"]
            numValue: ["50", "40", "30", "20", "10", "0"]
        }
    },
    props: ['value', 'shuaxin', 'Height', 'Width'],
    methods: {
        initEchart() {
            this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: '20%',
                    containLabel: true
                },
                xAxis: {
                    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                yAxis: {
                    name: "单位（KW）",
                    axisTick: {
                        show: true
                    },
                    nameTextStyle: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    // 控制数据参数最大值和最小值
                    interval: 200,
                    max: 1000

                },
                // 控住柱状图样式
                series: [
                    {
                        name: 'Email',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {
                            color: "#D9D9D9"
                        },
                        itemStyle: {
                            color: "#686868"
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: 'Union Ads',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {
                            color: "#CEE8E2"
                        },
                        itemStyle: {
                            color: "#17A735"
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [220, 182, 191, 234, 290, 330, 310]
                    },
                    {
                        name: 'Video Ads',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {
                            color: "#CCD9FF"
                        },
                        itemStyle: {
                            color: "#3366FF"
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [150, 232, 201, 154, 190, 330, 410]
                    },
                ]
            };

            this.vm.setOption(option13);
        },
        changeTheme(val) {
            console.log(val);
        }

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
    }
}
</script>
<style  scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}

.speed {
    width: 140px;
    height: 182px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 1px;
    margin-top: 60px;

    color: #fff;
    font-size: 14px;
    font-weight: 500;
    background: #51A34B;
    position: relative;

    overflow: hidden;
}

.item {
    width: 100%;
    transition: height ease-out 0.3s;
}

.title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@keyframes design {
    from {
        height: 0%;
    }

    to {
        height: 100%;
    }
}

.powerMonitor {
    display: flex;
}
</style>
