<template>
    <div style="width: 100%;">
        <div style="display: flex;">
            <div style="width: 50%;">
                <img src="" alt="">
            </div>
            <div style="width: 50%;">
                <div style="display: flex; justify-content:space-around; margin-top: 10px;">
                    <div>
                        <div style="font-size: 16px; color: #595959; font-weight: 400;">工作状态
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div style="font-size: 36px; color: #51A34B; font-weight: 500; margin-top: 10px;">运行</div>
                    </div>
                    <div>
                        <div style="font-size: 16px; color: #595959; font-weight: 400;">入网状态</div>
                        <div style="font-size: 36px; color: #1f1f1f; font-weight: 500;margin-top: 10px;">并网</div>
                    </div>
                </div>
                <div style="display: flex; justify-content:space-around;margin-top: 10%;">
                    <div>
                        <div style="font-size: 16px; color: #595959; font-weight: 400;">充电模式</div>
                        <div style="font-size: 36px; color: #FF7300; font-weight: 500;margin-top: 10px;">充电中</div>
                    </div>
                    <div>
                        <div style="font-size: 16px; color: #595959; font-weight: 400;">运行模式</div>
                        <div style="font-size: 36px; color: #1F1F1F; font-weight: 500;margin-top: 10px;">恒功</div>
                    </div>
                </div>
                <div style="width: 87%; margin-left: 13%; margin-top: 40px;">
                    <div style="width: 100%; margin-bottom: 8px; position: relative;  font-size: 14px; color: #1F1F1F;">
                        <span style="position: absolute;left: 2px; top: -20px;">直流电流</span>
                        <span style="position: absolute;right: 2px; top: -20px;">{{365*flow}}A</span>
                    </div>
                    <div style="width: 100%;"><el-progress :percentage="flow*100" :format="format"
                            :show-text="false" color="#51A34B"></el-progress></div>
                    <div style="width: 100%; position: relative;  font-size: 12px; color: #696969;">
                        <span style="position: absolute;left: 2px; top: 3px;">0</span>
                        <span style="position: absolute;right: 2px; top: 4px;">365A</span>
                    </div>
                </div>
                <div style="width: 87%; margin-left: 13%; margin-top: 60px;">
                    <div style="width: 100%; margin-bottom: 8px; position: relative; font-size: 14px; color: #1F1F1F;">
                        <span style="position: absolute;left: 2px; top: -20px;">直流电压</span>
                        <span style="position: absolute;right: 2px; top: -20px;">{{1000*Pressure}}V</span>
                    </div>
                    <div style="width: 100%;"><el-progress :percentage="Pressure*100" :format="format"
                            :show-text="false" color="#51A34B"></el-progress></div>
                    <div style="width: 100%; position: relative; font-size: 12px; color: #696969;">
                        <span style="position: absolute;left: 2px; top: 3px;">0</span>
                        <span style="position: absolute;right: 2px; top: 3px;">1000V</span>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 95%; margin: auto; border: 1px solid #ccc; margin-top: 60px; border-bottom: 0px;">
            <div style="display: flex; width: 100%; height: 30px; line-height: 30px; border-bottom: 1px solid #ccc;" v-for="(item,index) in newData" :key="index">
                <div style="width: 70%; padding-left: 3%;">{{ item.name }}</div>
                <div style="width: 15%;background-color: #EBEBEB; text-align: center;">{{ item.num }}</div>
                <div style="width: 15%;background-color: #EBEBEB; text-align: center;">{{ item.attribute }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            newData:[
                {
                    name:"有功功率",
                    num:"20",
                    attribute:"%"
                },
                {
                    name:"无功功率",
                    num:"75",
                    attribute:"°C"
                },
                {
                    name:"日充电量",
                    num:"2780",
                    attribute:"kwh"
                },
                {
                    name:"日放电量",
                    num:"2780",
                    attribute:"kwh"
                },
                {
                    name:"月充电量",
                    num:"2780",
                    attribute:"kwh"
                },
                {
                    name:"月放电量",
                    num:"2780",
                    attribute:"kwh"
                },

            ],
            flow:0.4,
            Pressure:0.4
        }
    },
}
</script>