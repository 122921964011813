<template>
    <div ref="text_modle13" :style="{ width: Width, height: Height, position: 'relative' }" class="powerTesting">
        <el-select v-model="value" clearable :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
            <el-option v-for="item in powerTestingData" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <div class="card-content2">
            <chartBoard ref="main_modle13" :option="chartOption1" />
        </div>
        <div style="position: absolute; top: 20%; right: 20px;">
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; height: 12px; border-radius: 50%;  background:#3366FF; margin-right: 4px;"></span><span>有功</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; height: 12px; border-radius: 50%;  background:#B4D0FF; margin-right: 4px;"></span><span>无功</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #11A826; margin-right: 4px;"></span><span>功因数</span>
            </div>
        </div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
    name: 'detailsmask001',
    components: {
        chartBoard: () => import('@/component/chartBoard.vue'),
    },
    props: ['value', 'shuaxin', 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            chartOption1: {},
            dialogCategory: false,
            alarmWarningData1: [],
            alarmWarningData2: [],
            alarmWarningData3: [],
            powerTestingData:[]
        }
    },

    methods: {
        a() {
            console.log(this.checked1)
        },
        initEchart() {
            // this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: '20%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    extraCssText: 'width:170px;height:40px;',
                    axisPointer: {
                        type: 'none'
                    },
                    formatter: function (params) {
                        return params[0].name + ' : ' + '(' + params[0].value + ':' + params[1].value + ':' + params[2].value + ")"
                    }
                },
                xAxis: {
                    data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '单位(kWh)',
                        min: 0,
                        max: 1000,
                        interval: 200,
                        axisLabel: {
                            color: "rgba(134,144,156,1)",
                            fontSize: 14,
                        }
                    },
                    {
                        type: 'value',
                        name: '元',
                        min: 0,
                        max: 100,
                        interval: 20,
                        axisLabel: {
                            color: "rgba(134,144,156,1)",
                            fontSize: 14,
                        }
                    }
                ],
                // 控住柱状图样式
                series: [
                    {
                        name: 'Evaporation',
                        type: 'bar',
                        barWidth: 10,
                        itemStyle:{
                            color:"#3366FF"
                        },
                        smooth:false,
                        data: [
                            2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
                        ]
                    },
                    {
                        name: 'Precipitation',
                        type: 'bar',
                        barWidth: 10,
                        itemStyle:{
                            color:"#B4D0FF"
                        },
                        smooth:false,
                        data: [
                            2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                        ]
                    },
                    {
                        name: 'Temperature',
                        type: 'line',
                        yAxisIndex: 1,
                        smooth:false,
                        itemStyle:{
                            color:"#11A826"
                        },
                        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
                    }
                ]
            };
            this.updateChart('chart1', 'chartOption1', option13)
        },
        updateChart: function (refName, optionName, option13 = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option13
            setTimeout(() => {
                this.$refs.main_modle13.updateChartView()
            }, 500)
        },

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>
<style lang="less">
.powerTesting {
    .el-input__inner {
        width: 256px;
        height: 32px;
    }
    .el-input__icon{
        line-height: 32px;
    }
}
</style>