<template>
    <div class="ESOne">
        <div style="width: 120px; height: 120px; margin: auto;">
            <el-progress style="white-space: pre;" type="dashboard" :percentage="70"
                color="#51A34B" :format="format"></el-progress>
        </div>

        <div style="margin-top: 20px;" class="ESOne-straight">
            <span class="demonstration">等效利用率</span>
            <el-progress :percentage="90" color="#51A34B"></el-progress>
        </div>
        <div style="margin-top: 20px;" class="ESOne-straight">
            <span class="demonstration">储能损耗率</span>
            <el-progress :percentage="67" color="#FF7300"></el-progress>
        </div>
        <div style="margin-top: 20px;" class="ESOne-straight">
            <span class="demonstration">利用系数</span>
            <el-progress :percentage="100" color="#F03040"></el-progress>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            colors: "#51A34B"
        }
    },
    methods: {
        format(percentage) {
            return percentage != 0 ? `${percentage}` + '\n\n' + `综合评价` : `${percentage}%`;
        }
    }
}
</script>
<style lang="less">
.ESOne {
    .el-progress-circle {
        width: 120px !important;
        height: 120px !important;
    }

    .ESOne-straight {
        .el-progress__text {
            position: absolute;
            top: -12px;
            right: 52px;
        }
    }

}
</style>