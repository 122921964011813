<template>
    <div id="energy" style="margin-top: 10px;height: inherit;overflow: auto;">
        <div>
            <GridLayout :col-num="12"
                :style="{ position: 'relative', overflowX: 'hidden', overflowY: 'hidden', top: '-10px' }"
                :is-draggable="draggable" :is-resizable="resizable" :layout="layout" :responsive="responsive"
                :row-height="60" :use-css-transforms="true" :vertical-compact="true">
                <GridItem v-for="(item, indexVar) in layout" :key="indexVar" :h="item.h" :i="item.i" :minW="item.minw"
                    :minH="item.minh" :static="item.static" :w="item.w" :x="item.x" :y="item.y"
                     @resized="resized">
                     <!-- :isDraggable="item.Draggable" -->
                    <div style="width: 100%; height: 100%; background: white">
                        <div style="width:100%" v-if="item.i == 1">
                            <bulletinBoard style="width:100%;" :top="false" title="累计充放总量统计" :whiteT="true">
                                <div style="display: flex; width: 100%;">
                                    <div style="display: flex; width: 100%; margin-bottom: 25px;">
                                        <div style="margin-right: 20%; margin-left: 10%;">
                                            <div
                                                style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                                年总充总量</div>
                                            <div
                                                style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#1F1F1F">
                                                110.33 <span style="font-size: 14px; color: #000000;">Mwh</span></div>
                                            <div
                                                style="display: flex;justify-content: center;margin-top: 15px;position: relative;left: -11px;    align-items: center;">
                                                <div>
                                                    <i style="font-size: 13px;position: relative;top: -1px;color: #F53F3F;"
                                                        class="el-icon-caret-bottom"></i>
                                                </div>
                                                <div style="font-size: 13px;color: #F53F3F;">11.2%</div>
                                                <div style="font-size: 12px;margin-left: 4px;color:#b1adad">较上年</div>
                                            </div>
                                        </div>
                                        <div style="">
                                            <div
                                                style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                                年工总放电量</div>
                                            <div
                                                style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#1F1F1F">
                                                98.23 <span style="font-size: 14px; color: #000000;">Mwh</span></div>
                                            <div
                                                style="display: flex;justify-content: center;margin-top: 15px;position: relative;left: -11px;    align-items: center;">
                                                <div>
                                                    <i style="font-size: 13px;position: relative;top: -1px;color: #F53F3F;"
                                                        class="el-icon-caret-bottom"></i>
                                                </div>
                                                <div style="font-size: 13px;color: #F53F3F;">11.2%</div>
                                                <div style="font-size: 12px;margin-left: 4px;color:#b1adad">较上年</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 2">
                            <bulletinBoard style="width:100%" :top="true" title="PCS充放电统计" :whiteT="true">
                                <ESNineVue style="width:100%" :Height="ESNineHeight" :Width="ESNineWidth"></ESNineVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 3">
                            <bulletinBoard style="width:100%;" :top="false" title="蓄电池" :whiteT="true">
                                <div style="width: 100%;">
                                    <div style="display: flex; width: 100%; margin-bottom: 25px;">
                                        <div style="margin-right: 30%; margin-left: 10%; margin-top: 40px;">
                                            <div
                                                style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                                电池总数</div>
                                            <div
                                                style="font-size: 24px;font-weight: 600;position: relative;color:#1F1F1F">
                                                1100 <span style="font-size: 14px; color: #000000;">个</span></div>
                                        </div>
                                        <div style="margin-top: 40px;">
                                            <div
                                                style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                                装机功率</div>
                                            <div
                                                style="font-size: 24px;font-weight: 600;position: relative;color:#1F1F1F">
                                                200 <span style="font-size: 14px; color: #000000;">kW</span></div>
                                        </div>
                                    </div>
                                    <div style="display: flex; width: 100%; margin-bottom: 25px;">
                                        <div style="margin-right: 30%; margin-left: 10%; margin-top: 40px;">
                                            <div
                                                style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                                装机容量</div>
                                            <div
                                                style="font-size: 24px;font-weight: 600;position: relative;color:#1F1F1F">
                                                200 <span style="font-size: 14px; color: #000000;">Mwh</span></div>
                                        </div>
                                        <div style="margin-top: 40px;">
                                            <div
                                                style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                                工作时长</div>
                                            <div
                                                style="font-size: 24px;font-weight: 600;position: relative;color:#1F1F1F">
                                                1125 <span style="font-size: 14px; color: #000000;">H</span></div>
                                        </div>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 4">
                            <bulletinBoard style="width:100%" :top="false" title="PCS实时数据" :whiteT="true">
                                <ESOneVue style="width:100%"></ESOneVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 5">
                            <bulletinBoard style="width:100%" :top="true" title="储能电站运行指标" :whiteT="true">
                                <div style="display:flex;margin-bottom: 25px;">
                                    <div style="margin-right: 80px;">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            年总充总量</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#165DFF">
                                            1120.33 <span style="font-size: 14px; color: #000000;">Mwh</span></div>
                                        <div
                                            style="display: flex;justify-content: center;margin-top: 15px;position: relative;left: -11px;    align-items: center;">
                                            <div>
                                                <i style="font-size: 13px;position: relative;top: -1px;color: #F53F3F;"
                                                    class="el-icon-caret-bottom"></i>
                                            </div>
                                            <div style="font-size: 13px;color: #F53F3F;">11.2%</div>
                                            <div style="font-size: 12px;margin-left: 4px;color:#b1adad">较上年</div>
                                        </div>
                                    </div>
                                    <div style="margin-right: 80px;">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            年工总放电量</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#165DFF">
                                            798.23 <span style="font-size: 14px; color: #000000;">Mwh</span></div>
                                        <div
                                            style="display: flex;justify-content: center;margin-top: 15px;position: relative;left: -11px;    align-items: center;">
                                            <div>
                                                <i style="font-size: 13px;position: relative;top: -1px;color: #F53F3F;"
                                                    class="el-icon-caret-bottom"></i>
                                            </div>
                                            <div style="font-size: 13px;color: #F53F3F;">11.2%</div>
                                            <div style="font-size: 12px;margin-left: 4px;color:#b1adad">较上年</div>
                                        </div>
                                    </div>
                                </div>
                                <ESTenVue style="width:100%" :Height="ESTenHeight" :Width="ESTenWidth"></ESTenVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 6">
                            <bulletinBoard style="width:100%" :top="false" title="电池信息" :whiteT="true">
                                <ESSixVue style="width:100%"></ESSixVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 7">
                            <bulletinBoard style="width:100%" :top="false" title="动环监控" :whiteT="true">
                                <ESTwelveVue style="width:100%"></ESTwelveVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 8">
                            <bulletinBoard style="width:100%" :top="false" title="PCS实时数据" :whiteT="true">
                                <ESTFourVue style="width:100%" :Height="ESFourHeight" :Width="ESFourWidth"></ESTFourVue>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 9">
                            <bulletinBoard style="width:100%" :top="false" title="PCS汇总数据" :whiteT="true">
                                <div style="width: 100%;">
                                    <div
                                        style="width: 80%; height: 300px; border: 1px solid #ebebeb; margin: 20px auto;">
                                        <!-- <img src="../../assets/imgs/login/newlogin.png" alt=""> -->
                                    </div>
                                    <div v-for="(item, index) in newData" :key="index"
                                        style="display: flex; border: 1px solid #ebebeb;  height: 30px; line-height: 30px; text-align: center; width: 90%; margin: auto; margin-bottom:-1px;">
                                        <div style="width: 70%; text-align: left; padding-left: 20px;">{{ item.name }}
                                        </div>
                                        <div style="width: 20%; background-color: #EBEBEB;">{{ item.num }}</div>
                                        <div style="width: 10%; background-color: #EBEBEB;">{{ item.Company }}</div>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 10">
                            <bulletinBoard style="width:100%" :top="false" title="动环监控" :whiteT="true">
                                <div class="ESmonitor" style="width: 100%;">
                                    <div style="width: 200px; height: 200px; margin: auto; margin-top: 20px;">
                                        <el-progress style="white-space: pre;" :format="format" type="dashboard"
                                            :percentage="70" color="#FF7300"></el-progress>
                                    </div>

                                    <div style="margin-top: 40px;" class="ESOne-straight">
                                        <span class="demonstration">温度</span>
                                        <!-- <span
                                            style="margin-left: 68%;font-size: 14px;color: #696969;">20%</span> -->
                                        <el-progress :percentage="27.22" color="#FF7300"></el-progress>
                                        <span style="font-size: 14px;color: #696969;">0</span>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 11">
                            <bulletinBoard style="width:100%" :top="false" title="动环监控" :whiteT="true">
                                <div class="ESmonitor" style="width: 100%;">
                                    <div style="width: 200px;height: 200px; margin: auto; margin-top: 20px;">
                                        <el-progress style=" white-space: pre;" type="dashboard" :format="format1"
                                            :percentage="70" color="#F03040"></el-progress>
                                    </div>

                                    <div style="margin-top: 40px;" class="ESOne-straight">
                                        <span class="demonstration">湿度</span>
                                        <!-- <span
                                            style="margin-left: 68%;font-size: 14px;color: #696969;">80%</span> -->
                                        <el-progress :percentage="33.79" color="#F03040"></el-progress>
                                        <span style="font-size: 14px;color: #696969;">0</span>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 12">
                            <bulletinBoard style="width:100%" :top="false" title="动环监控" :whiteT="true">
                                <div class="ESmonitor" style="width: 100%;">
                                    <div style="width: 200px; height: 200px; margin: auto; margin-top: 20px;">
                                        <el-progress style="white-space: pre;" type="dashboard" :format="format2"
                                            :percentage="70" color="#51A34B"></el-progress>
                                    </div>

                                    <div style="margin-top: 40px;" class="ESOne-straight">
                                        <span class="demonstration">风机流量</span>
                                        <!-- <span
                                            style="margin-left: 60%;font-size: 14px;color: #696969;">40%</span> -->
                                        <el-progress :percentage="100" color="#51A34B"></el-progress>
                                        <span style="font-size: 14px;color: #696969;">0</span>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 13">
                            <bulletinBoard style="width:100%" :top="false" title="温度控制" :whiteT="true">
                                <div class="ESmonitor" style="width: 100%;">
                                    <div style="display: flex;margin-top: 20px;">
                                        <i class="el-icon-s-order" style="font-size: 60px;"></i>
                                        <div style="margin-left: 20px;">
                                            <p style="font-size: 30px; color: #000000; margin-bottom: 5px;">13</p>
                                            <p>箱内温度(℃)</p>
                                        </div>
                                    </div>
                                    <div style="margin-top: 30px;" class="ESOne-straight">
                                        <el-progress :percentage="100" color="#51A34B"></el-progress>
                                        <span style="font-size: 14px;color: #696969;">0</span>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 14">
                            <bulletinBoard style="width:100%" :top="false" title="湿度控制" :whiteT="true">
                                <div class="ESmonitor" style="width: 100%;">
                                    <div style="display: flex;margin-top: 20px;">
                                        <i class="el-icon-s-order" style="font-size: 60px;"></i>
                                        <div style="margin-left: 20px;">
                                            <p style="font-size: 30px; color: #000000; margin-bottom: 5px;">20</p>
                                            <p>湿度控制(%)</p>
                                        </div>
                                    </div>
                                    <div style="margin-top: 30px;" class="ESOne-straight">
                                        <el-progress :percentage="100" color="#51A34B"></el-progress>
                                        <span style="font-size: 14px;color: #696969;">0</span>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 15">
                            <bulletinBoard style="width:100%" :top="false" title="风机流量控制" :whiteT="true">
                                <div class="ESmonitor" style="width: 100%;">
                                    <div style="display: flex;margin-top: 20px;">
                                        <i class="el-icon-s-order" style="font-size: 60px;"></i>
                                        <div style="margin-left: 20px;">
                                            <p style="font-size: 30px; color: #000000; margin-bottom: 5px;">2.3</p>
                                            <p>风机空气流量(m³/min)</p>
                                        </div>
                                    </div>
                                    <div style="margin-top: 30px;" class="ESOne-straight">
                                        <el-progress :percentage="100" color="#51A34B"></el-progress>
                                        <span style="font-size: 14px;color: #696969;">0</span>
                                    </div>
                                </div>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 16">
                            <bulletinBoard style="width:100%" :top="false" title="质量总览" :whiteT="true">
                                <QualityOverview style="width:100%" :Height="QualityOverviewHeight"
                                    :Width="QualityOverviewWidth"></QualityOverview>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 17">
                            <bulletinBoard style="width:100%" :top="false" title="功率监测" :whiteT="true">
                                <PowerTesting style="width:100%" :Height="PowerTestingHeight"
                                    :Width="PowerTestingWidth"></PowerTesting>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 18">
                            <bulletinBoard style="width:100%" :top="false" title="电流畸变率" :whiteT="true">
                                <DistortionRate style="width:100%" :Height="DistortionRateHeight"
                                    :Width="DistortionRateWidth"></DistortionRate>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 19">
                            <bulletinBoard style="width:100%" :top="false" title="功率监测" :whiteT="true">
                                <PowerMonitor style="width:100%" :Height="PowerMonitorHeight"
                                    :Width="PowerMonitorWidth"></PowerMonitor>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 20">
                            <bulletinBoard style="width:100%" :top="false" title="交流测电流" :whiteT="true">
                                <energyCom1 style="width:100%"></energyCom1>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 21">
                            <bulletinBoard style="width:100%" :top="false" title="交流测电压" :whiteT="true">
                                <energyCom2 style="width:100%"></energyCom2>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 22">
                            <bulletinBoard style="width:100%" :top="false" title="基本情况" :whiteT="true">
                                <energyCom3 style="width:100%"></energyCom3>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 23">
                            <bulletinBoard style="width:100%" :top="false" title="电池信息" :whiteT="true">
                                <energyCom4 style="width:100%"></energyCom4>
                            </bulletinBoard>
                        </div>
                    </div>
                </GridItem>
            </GridLayout>
        </div>
    </div>
</template>
<script>
import bulletinBoard from "@/component/bulletinBoard";
import ESNineVue from "@/component/energyCom/ESNine";
import ESOneVue from "@/component/energyCom/ESOne";
import ESTenVue from "@/component/energyCom/ESTen";
import ESTwelveVue from "@/component/energyCom/ESTwelve";
import ESTFourVue from "@/component/energyCom/ESFour";
import ESSixVue from "@/component/energyCom/ESSix";
import QualityOverview from "@/component/energyCom/qualityOverview";
import PowerTesting from "@/component/energyCom/powerTesting";
import DistortionRate from "@/component/energyCom/distortionRate";
import PowerMonitor from "@/component/energyCom/powerMonitor";
import energyCom1 from "@/component/energyCom/energyCom1";
import energyCom2 from "@/component/energyCom/energyCom2";
import energyCom3 from "@/component/energyCom/energyCom3";
import energyCom4 from "@/component/energyCom/energyCom4";
import { GridLayout, GridItem } from 'vue-grid-layout'
export default {
    components: { bulletinBoard, GridItem, GridLayout, ESNineVue, ESOneVue, ESTenVue, ESTwelveVue, ESTFourVue, ESSixVue, QualityOverview, PowerTesting, DistortionRate, PowerMonitor, energyCom1, energyCom2, energyCom3,energyCom4 },
    data() {
        return {
            ESNineHeight: "300px",
            ESNineWidth: "570px",
            ESTenHeight: "200px",
            ESTenWidth: "570px",
            ESFourHeight: "270px",
            ESFourWidth: "570px",
            QualityOverviewHeight: "200px",
            QualityOverviewWidth: "200px",
            PowerTestingHeight: "270px",
            PowerTestingWidth: "570px",
            DistortionRateHeight: "270px",
            DistortionRateWidth: "570px",
            PowerMonitorHeight: "320px",
            PowerMonitorWidth: "100%",
            layout: [
                { x: 0, y: 2, w: 3, h: 3, i: 1, minw: 3, minh: 5 },
                { x: 3, y: 2, w: 6, h: 6, i: 2, minw: 3, minh: 5 },
                { x: 9, y: 2, w: 3, h: 5, i: 3, minw: 3, minh: 5 },
                { x: 0, y: 4, w: 3, h: 9, i: 4, minw: 3, minh: 5 },
                { x: 3, y: 7, w: 6, h: 6, i: 5, minw: 3, minh: 5 },
                { x: 9, y: 7, w: 3, h: 8, i: 6, minw: 3, minh: 5 },
                { x: 0, y: 13, w: 3, h: 5, i: 7, minw: 3, minh: 5 },
                { x: 3, y: 13, w: 6, h: 5, i: 8, minw: 3, minh: 5 },
                { x: 9, y: 13, w: 3, h: 8, i: 9, minw: 3, minh: 5 },
                { x: 0, y: 18, w: 3, h: 6, i: 10, minw: 3, minh: 5 },
                { x: 3, y: 18, w: 3, h: 6, i: 11, minw: 3, minh: 5 },
                { x: 6, y: 18, w: 3, h: 6, i: 12, minw: 3, minh: 5 },
                { x: 0, y: 24, w: 3, h: 3, i: 13, minw: 3, minh: 5 },
                { x: 3, y: 24, w: 3, h: 3, i: 14, minw: 3, minh: 5 },
                { x: 6, y: 24, w: 3, h: 3, i: 15, minw: 3, minh: 5 },
                { x: 0, y: 27, w: 3, h: 6, i: 16, minw: 3, minh: 5 },
                { x: 3, y: 27, w: 6, h: 6, i: 17, minw: 3, minh: 5 },
                { x: 0, y: 33, w: 6, h: 6, i: 18, minw: 3, minh: 5 },
                { x: 0, y: 39, w: 9, h: 7, i: 19, minw: 3, minh: 5 },
                { x: 0, y: 46, w: 6, h: 6, i: 20, minw: 3, minh: 5 },
                { x: 6, y: 46, w: 6, h: 6, i: 21, minw: 3, minh: 5 },
                { x: 0, y: 52, w: 6, h: 9, i: 22, minw: 3, minh: 5 },
                { x: 6, y: 52, w: 3, h: 6, i: 23, minw: 3, minh: 5 },
            ],
            newData: [
                {
                    name: "PCS台数",
                    num: 8,
                    Company: "台",
                },
                {
                    name: "运行模式",
                    num: "充电",
                    Company: "",
                },
                {
                    name: "有功功率",
                    num: 20,
                    Company: "kW",
                },
                {
                    name: "无功功率",
                    num: 8,
                    Company: "kW",
                },
            ],
            responsive: true,
            draggable: true,
            resizable: true,
        };
    },
    methods: {
        resized(i, newH, newW, newHPx, newWPx) {
            console.log("RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx)
            if (i == 1) {
                this.$nextTick().then(() => {
                    this.Threewidth = newWPx - 40 + 'px'
                    this.ThreeHeight = newHPx - 120 + 'px'
                })
            }
            if (i == 2) {
                this.$nextTick().then(() => {
                    this.ESNineWidth = newWPx - 200 + 'px'
                    this.ESNineHeight = newHPx - 200 + 'px'
                })
            }
            if (i == 5) {
                this.$nextTick().then(() => {
                    this.ESTenWidth = newWPx - 120 + 'px'
                    this.ESTenHeight = newHPx - 200 + 'px'
                })
            }
            if (i == 8) {
                this.$nextTick().then(() => {
                    this.ESFourWidth = newWPx - 120 + 'px'
                    this.ESFourHeight = newHPx - 100 + 'px'
                })
            }
            if (i == 16) {
                this.$nextTick().then(() => {
                    this.QualityOverviewWidth = newWPx - 0 + 'px'
                    this.QualityOverviewHeight = newHPx - 0 + 'px'
                })
            }
        },
        format(percentage) {
            return percentage != 0 ? `${percentage}` + '\n\n' + `温度(°C)` : `${percentage}%`;
        },
        format1(percentage) {
            return percentage != 0 ? `${percentage}` + '\n\n' + `湿度(%)` : `${percentage}%`;
        },
        format2(percentage) {
            return percentage != 0 ? `${percentage}` + '\n\n' + `风机流量(m³/min)` : `${percentage}%`;
        }
    },
};
</script>
<style lang="less">
.W07Box {
    width: 221px;
    height: 84px;
    background: #fadbdb;
    border: 1px solid #e01e5a;
    border-radius: 8px;
    margin: 15% auto;

    .W07Box-content {
        margin: 10px;
    }

    p {
        width: 197px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-6;
        font-weight: 500;
        text-align: left;
        color: #e01e5a;
        line-height: 20px;
    }
}

.ESmonitor {
    .el-progress-circle {
        width: 200px !important;
        height: 200px !important;
    }

    .ESOne-straight {
        .el-progress__text {
            position: absolute;
            top: 18px;
            right: 52px;
        }
    }
}
</style>